var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wave" }, [
    _c("h2", [_vm._v(_vm._s(_vm.title))]),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.title,
          expression: "title"
        }
      ],
      attrs: { type: "text" },
      domProps: { value: _vm.title },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          ) {
            return null
          }
          return _vm.addTodo($event)
        },
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.title = $event.target.value
        }
      }
    }),
    _vm.todos.length
      ? _c(
          "ul",
          _vm._l(_vm.todos, function(todo, i) {
            return _c("li", { key: i }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: todo.done,
                    expression: "todo.done"
                  }
                ],
                attrs: { type: "checkbox" },
                domProps: {
                  checked: Array.isArray(todo.done)
                    ? _vm._i(todo.done, null) > -1
                    : todo.done
                },
                on: {
                  change: function($event) {
                    var $$a = todo.done,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && _vm.$set(todo, "done", $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            todo,
                            "done",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(todo, "done", $$c)
                    }
                  }
                }
              }),
              _c("span", { class: { done: todo.done } }, [
                _vm._v(" " + _vm._s(todo.title))
              ])
            ])
          }),
          0
        )
      : _c("div", [_vm._v(" 暂无数据 ")]),
    _c("div", [
      _vm._v(" 全选"),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.allDone,
            expression: "allDone"
          }
        ],
        attrs: { type: "checkbox" },
        domProps: {
          checked: Array.isArray(_vm.allDone)
            ? _vm._i(_vm.allDone, null) > -1
            : _vm.allDone
        },
        on: {
          change: function($event) {
            var $$a = _vm.allDone,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.allDone = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.allDone = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.allDone = $$c
            }
          }
        }
      }),
      _vm._v(" " + _vm._s(_vm.active) + " / " + _vm._s(_vm.all) + " ")
    ]),
    _vm.active < _vm.all
      ? _c("button", { on: { click: _vm.clear } }, [_vm._v("清理")])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }